import { Alert } from '@mui/material';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Payment from '../components/payment/payment';
import PaymentComponent from '../components/payment/payment';
import Completion from '../components/payment/Completion';

const HomePage = lazy(() => import('../pages/home'));
const AboutPage = lazy(() => import('../pages/about'));
const ServicePage = lazy(() => import('../pages/services'));
const TermConditionPage = lazy(() => import('../pages/term-condition'));
const PrivacyPolicyPage = lazy(() => import('../pages/privacy-policy'));
const LoginPage = lazy(() => import('../pages/login'));
const MyAccountPage = lazy(() => import('../pages/my-account'));
const BookingPage = lazy(() => import('../pages/booking'));
const BlogDetailPage = lazy(() => import('../pages/blog-detail'));
const AnnounceDetailPage = lazy(() => import('../pages/announce-detail'));
const ArticleDetailPage = lazy(() => import('../pages/article-detail'));

const ContactUsPage = lazy(() => import('../pages/contact'));
const BlogPage = lazy(() => import('../pages/blog'));
const ArticlePage = lazy(() => import('../pages/article'));

const AnnouncementPage = lazy(() => import('../pages/announcement'));

const LoginWithPhone = lazy(() => import('../components/login/LoginWithPhone'));
const Register = lazy(() => import('../components/register/Register'));
const ErrorPage = lazy(() => import('../pages/error'));

const MainRoutes = ({setIsAuthenticated}) => {
  useEffect(()=>{
    setIsAuthenticated(sessionStorage.getItem("auth") ? true : false)
  },[])

  // console.log(setIsAuthenticated)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about-us" element={<AboutPage />} />
        <Route path="services" element={<ServicePage />} />
        <Route path="Checkout" element={<PaymentComponent/>} />

        <Route path="/term-condition" element={<TermConditionPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-detail" element={<BlogDetailPage />} />
        <Route path="/announcement" element={<AnnouncementPage />} />
        <Route path="/announcement-detail" element={<AnnounceDetailPage />} />
        <Route path="/article" element={<ArticlePage />} />
        <Route path="/article-detail" element={<ArticleDetailPage />} />


        <Route path="register" element={<Register />} />
        {process.env.REACT_APP_LOGIN_TYPE === 'email' ? (
          <Route path="login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
        ) : (
          <Route path="login" element={<LoginWithPhone />} />
        )}
        <Route path="profile" element={<MyAccountPage setIsAuthenticated={setIsAuthenticated}/>} />
        <Route path="booknow" element={<BookingPage  setIsAuthenticated={setIsAuthenticated}/>} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="contact-us" element={<ContactUsPage />} />
        {/* <Route path="changepassword" element={<ChangePassword />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} /> */}
        {/* <Route path="/confrim" element={<Confrim />} /> */}
        {/* <Route path='/verify' element={<Verify/>} /> */}
        {/* <Route path="/reserve" element={<HiremeBooknow />} /> */}
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
